<template>
  <div>
    <div class="surveyApprovalQuestions">
      <br />
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-container class="bg-light p-2 rounded" v-else>
        <b-row>
          <b-col md="12">
            <div class="closeBtn mb-2 text-right">
              <p @click="closeCurrentTab()" class="btn m-0">
                <i class="fas fa-window-close fa-4x text-danger"></i>
              </p>
            </div>
            <h6 style="font-size: 24px; font-weight: 600; text-align: center">
              Add Questions for:
              <span class="text-info">{{ registrationDetails.name }}</span>
            </h6>
            <b-button
              variant="white"
              v-if="approvalQuestions.length == 0 || !addQues"
              class="text-primary"
              @click="addQues = true && prepareAddingQuestion()"
              ><i class="fas fa-plus-square fa-2x"></i
            ></b-button>
            <b-col>
              <b-form-group id="input-group-1" label-for="input-1" description="">
                <label class="" for="">Form Guidelines:</label>
                <b-form-input id="input-1" v-model="form_guideline" type="text" placeholder="Enter a text that can see when fill the form"
                  ></b-form-input>
              <b-button class="mt-1" @click="saveFormGuideline" v-if="(registrationDetails.form_guideline!=form_guideline)" variant="primary">Save</b-button>
              </b-form-group>
            </b-col>

            <b-col >
              <b-button
                variant="secondary"
                class="text-white"
                @click="copyQuestionsModel()"
                >Copy questions</b-button
              >
            </b-col>
            <div class="questions" v-if="approvalQuestions.length != 0">
              <b-form
                @submit.prevent="prepareSurveyQuestionsPostData()"
                class="border-0"
              >
                <div
                  class="question border border-info rounded p-3 mt-2 bg-white"
                  v-for="(question, questionIndex) in approvalQuestions"
                  :key="questionIndex"
                >
                  <b-overlay
                    :show="loadQuestion"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <div class="quesView" v-if="question.disabled">
                      <div
                        class="quesNoAndQues"
                        style="  font-size: 14px;
                          font-weight: 600;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <p class="mb-2">
                          {{ question.question_no }}:
                          <span>{{ question.name }}</span>
                        </p>
                        <div v-if="question.id != ''">
                          <div v-if="question.disabled == true">
                            <b-button
                              variant="info"
                              style="font-size: 12px"
                              size="sm"
                              @click="editApprovalQuestion(questionIndex)"
                              >Edit</b-button
                            >
                            <span
                              class="text-danger btn p-0 m-0 ml-2"
                              @click="
                                deleteApprovalQuestionFromServer(
                                  question.id,
                                  questionIndex
                                )
                              "
                              ><i class="fas fa-trash"></i
                            ></span>
                          </div>
                          <div v-else>
                            <b-button
                              variant="info"
                              style="font-size: 12px"
                              size="sm"
                              v-if="question.disabled == false"
                              @click="
                                prepareApprovalQuestionsForUpdating(
                                  questionIndex
                                )
                              "
                              >Save</b-button
                            >
                          </div>
                        </div>
                      </div>

                      <div style="font-size: 12px; font-weight: 600">
                        <div class="d-flex flex-wrap">
                          <p
                            :class="
                              question.allow_attachment
                                ? 'text-success mb-2'
                                : 'text-danger mb-2'
                            "
                          >
                            <span
                              ><i
                                :class="
                                  question.allow_attachment
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              {{
                                question.allow_attachment
                                  ? "Attachment Allowed"
                                  : "Attachment not allowed"
                              }}</span
                            >
                          </p>
                          <p
                            :class="
                              question.input_attributes[0].answerMandatory
                                ? 'text-success mb-2 ml-2'
                                : 'text-danger mb-2 ml-2'
                            "
                          >
                            <span
                              ><i
                                :class="
                                  question.input_attributes[0].answerMandatory
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              {{
                                question.input_attributes[0].answerMandatory
                                  ? "Answer Mandatory"
                                  : "Answer not mandatory"
                              }}</span
                            >
                          </p>
                        </div>
                        <div class="mb-2 d-flex flex-wrap">
                          <span
                            :class="
                              question.input_attributes[0].shortAnswer
                                ? 'text-success'
                                : 'text-danger'
                            "
                            ><span
                              ><i
                                :class="
                                  question.input_attributes[0].shortAnswer
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              Short Answer</span
                            >
                          </span>
                          <span
                            :class="
                              question.input_attributes[0].paragraph
                                ? 'text-success ml-2'
                                : 'text-danger ml-2'
                            "
                            ><span
                              ><i
                                :class="
                                  question.input_attributes[0].paragraph
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              Paragraph</span
                            ></span
                          >
                        </div>

                        <div class="mb-2 d-flex flex-wrap">
                          <span
                            :class="
                              question.input_attributes[0].multiChoice
                                ? 'text-success'
                                : 'text-danger'
                            "
                            ><span
                              ><i
                                :class="
                                  question.input_attributes[0].multiChoice
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              Mutichoice</span
                            ></span
                          >
                          <span
                            :class="
                              question.input_attributes[0].checkboxes
                                ? 'text-success ml-2'
                                : 'text-danger ml-2'
                            "
                            ><span
                              ><i
                                :class="
                                  question.input_attributes[0].checkboxes
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              Radio Buttons</span
                            ></span
                          >
                          <span
                            :class="
                              question.input_attributes[0].dropdown
                                ? 'text-success ml-2'
                                : 'text-danger ml-2'
                            "
                            ><span
                              ><i
                                :class="
                                  question.input_attributes[0].dropdown
                                    ? 'far fa-check-circle ml-1'
                                    : 'far fa-times-circle ml-1'
                                "
                              ></i>
                              Dropdown</span
                            ></span
                          >
                        </div>

                        <div
                          class="mb-2 d-flex"
                          v-if="
                            question.input_attributes[0].multiChoice ||
                              question.input_attributes[0].checkboxes ||
                              question.input_attributes[0].dropdown
                          "
                        >
                          <p
                            v-for="(choice, choiceIndex) in question.answers"
                            :key="choiceIndex"
                            class="text-white p-1 bg-success rounded ml-2"
                          >
                            {{ choice }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="editView" v-else>
                      <b-form-group class="questionNo">
                        <div class="w-100 d-flex justify-content-between">
                          <div>
                            <b-form-input
                              placeholder="Q No"
                              style="width: 100px"
                              v-model="question.question_no"
                              :class="{
                                'form-group-error':
                                  $v.approvalQuestions.$each[questionIndex]
                                    .question_no.$error,
                              }"
                            ></b-form-input>

                            <span
                              class="input-error-message"
                              v-if="
                                !$v.approvalQuestions.$each[questionIndex]
                                  .question_no.required &&
                                  $v.approvalQuestions.$each[questionIndex]
                                    .question_no.$dirty
                              "
                              >Question Number is mandatory!</span
                            >
                          </div>

                          <div class="deleteQuestion" v-if="question.id == ''">
                            <span
                              class="btn p-0 m-0 text-danger"
                              @click="
                                approvalQuestions.splice(questionIndex, 1)
                              "
                              ><i class="fas fa-minus-circle"></i
                            ></span>
                          </div>

                          <div v-if="question.id != ''">
                            <b-button
                              variant="info"
                              style="font-size: 12px"
                              size="sm"
                              v-if="question.disabled == true"
                              @click="editApprovalQuestion(questionIndex)"
                              >Edit</b-button
                            >
                            <div v-else>
                              <b-button
                                variant="info"
                                style="font-size: 12px"
                                size="sm"
                                @click="
                                  prepareApprovalQuestionsForUpdating(
                                    questionIndex
                                  )
                                "
                                >Save</b-button
                              >
                              <span
                                class="text-danger btn p-0 m-0 ml-2"
                                @click="
                                  deleteApprovalQuestionFromServer(
                                    question.id,
                                    questionIndex
                                  )
                                "
                                ><i class="fas fa-trash"></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </b-form-group>

                      <b-form-group class="question">
                        <b-form-input
                          placeholder="Question"
                          v-model="question.name"
                          :class="{
                            'form-group-error':
                              $v.approvalQuestions.$each[questionIndex].name
                                .$error,
                          }"
                        ></b-form-input>
                        <span
                          class="input-error-message"
                          v-if="
                            !$v.approvalQuestions.$each[questionIndex].name
                              .required &&
                              $v.approvalQuestions.$each[questionIndex].name
                                .$dirty
                          "
                          >Question is mandatory!</span
                        >
                      </b-form-group>

                      <b-form-group label="1) Allow Attachment">
                        <div class="d-flex">
                          <b-form-checkbox
                            size="sm"
                            v-model="question.allow_attachment"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Allow Attachment</b-form-checkbox
                          >
                          <b-form-checkbox
                            size="sm"
                            v-model="
                              question.input_attributes[0].answerMandatory
                            "
                            :class="
                              {
                                'form-group-error':
                                  $v.approvalQuestions.$each[questionIndex]
                                    .allow_attachment.$error,
                              } && ' ml-4'
                            "
                            >Answer Mandatory</b-form-checkbox
                          >
                        </div>
                      </b-form-group>

                      <b-form-group label="2) Answer Type">
                        <div class="d-flex">
                          <b-form-checkbox
                            size="sm"
                            :disabled="
                              question.input_attributes[0].paragraph ||
                                question.input_attributes[0].multiChoice ||
                                question.input_attributes[0].checkboxes ||
                                question.input_attributes[0].dropdown
                            "
                            v-model="question.input_attributes[0].shortAnswer"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Short Answer</b-form-checkbox
                          >
                          <b-form-checkbox
                            size="sm"
                            :disabled="
                              question.input_attributes[0].shortAnswer ||
                                question.input_attributes[0].multiChoice ||
                                question.input_attributes[0].checkboxes ||
                                question.input_attributes[0].dropdown
                            "
                            v-model="question.input_attributes[0].paragraph"
                            class="ml-2"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Paragraph</b-form-checkbox
                          >
                        </div>
                      </b-form-group>

                      <b-form-group
                        label="3) Choices Display View for Students"
                      >
                        <div class="d-flex">
                          <b-form-checkbox
                            size="sm"
                            :disabled="
                              question.input_attributes[0].checkboxes ||
                                question.input_attributes[0].dropdown ||
                                question.input_attributes[0].shortAnswer ||
                                question.input_attributes[0].paragraph
                            "
                            v-model="question.input_attributes[0].multiChoice"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Multichoice</b-form-checkbox
                          >
                          <b-form-checkbox
                            size="sm"
                            :disabled="
                              question.input_attributes[0].multiChoice ||
                                question.input_attributes[0].dropdown ||
                                question.input_attributes[0].shortAnswer ||
                                question.input_attributes[0].paragraph
                            "
                            v-model="question.input_attributes[0].checkboxes"
                            class="ml-2"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Radio Buttons</b-form-checkbox
                          >
                          <b-form-checkbox
                            size="sm"
                            :disabled="
                              question.input_attributes[0].multiChoice ||
                                question.input_attributes[0].checkboxes ||
                                question.input_attributes[0].shortAnswer ||
                                question.input_attributes[0].paragraph
                            "
                            v-model="question.input_attributes[0].dropdown"
                            class="ml-2"
                            :class="{
                              'form-group-error':
                                $v.approvalQuestions.$each[questionIndex]
                                  .allow_attachment.$error,
                            }"
                            >Drop-down</b-form-checkbox
                          >
                        </div>
                      </b-form-group>

                      <b-form-group
                        v-if="
                          question.input_attributes[0].multiChoice ||
                            question.input_attributes[0].checkboxes ||
                            question.input_attributes[0].dropdown
                        "
                      >
                        <b-form-tags
                          placeholder="Enter choice and press enter"
                          v-model="question.answers"
                          :id="'questionChoices' + questionIndex"
                          tag-variant="info"
                          tag-pills
                          size="md"
                          class="text-center"
                          remove-on-delete
                          :tag-validator="null"
                          :class="{
                            'form-group-error':
                              $v.approvalQuestions.$each[questionIndex].answers
                                .$error,
                          }"
                        ></b-form-tags>
                        <span
                          class="input-error-message"
                          v-if="
                            !$v.approvalQuestions.$each[questionIndex].answers
                              .required &&
                              $v.approvalQuestions.$each[questionIndex].answers
                                .$dirty
                          "
                          >Minimum 2 choices is required!!</span
                        >
                      </b-form-group>
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.approvalQuestions.$each[questionIndex]
                            .allow_attachment.checked &&
                            $v.approvalQuestions.$each[questionIndex]
                              .allow_attachment.$dirty
                        "
                        >Please Select atleast one from above 3.</span
                      >
                    </div>
                  </b-overlay>
                </div>
                <br />
                <b-form-group>
                  <b-button
                    variant="primary"
                    size="sm"
                    :disabled="approvalBtn.disabled"
                    @click="prepareAddingQuestion()"
                    >{{ approvalBtn.add }}</b-button
                  >
                </b-form-group>
                <b-form-group class="text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    size="md"
                    :disabled="approvalBtn.disabled"
                    >{{ approvalBtn.sub }}</b-button
                  >
                </b-form-group>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal
      id="copyQuestion"
      title="Copy questions from another event"
      hide-footer
      size="md"
    >
      <div class="">
        <b-form-group>
          <b-form-select
            :options="regEventsForCopy"
            v-model="regEventIdForCopy"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-button
            v-if="!regEventsForCopyStatus"
            variant="primary"
            @click="copyQuestions()"
            size="sm"
            >Copy</b-button
          >
          <span v-else>loading...</span>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  created() {
    this.getRegistrationDetails();
  },
  data() {
    return {
      regEventsForCopy: [],
      regEventIdForCopy: null,
      regEventsForCopyStatus: true,
      fetchData: false,
      addQues: false,
      loadQuestion: false,
      approvalQuestions: [],
      registrationDetails: {},
      approvalBtn: {
        add: "Add Question",
        sub: "Submit",
        disabled: false,
      },
      form_guideline:'',
    };
  },
  validations: {
    approvalQuestions: {
      required,
      $each: {
        question_no: {
          required,
        },
        name: {
          required,
        },
        allow_attachment: {
          checked: (val, sAns) => {
            val;
            if (
              sAns.allow_attachment == false &&
              sAns.input_attributes[0].shortAnswer == false &&
              sAns.input_attributes[0].paragraph == false &&
              sAns.input_attributes[0].multiChoice == false &&
              sAns.input_attributes[0].checkboxes == false &&
              sAns.input_attributes[0].dropdown == false
            ) {
              return false;
            } else {
              return true;
            }
          },
        },

        answers: {
          required: requiredIf(function(choice) {
            let checkChoice = choice.input_attributes[0];
            if (
              checkChoice.multiChoice == true ||
              checkChoice.checkboxes == true ||
              checkChoice.dropdown == true
            ) {
              return true;
            }
          }),
        },
      },
    },
  },
  methods: {
    async deleteApprovalQuestionFromServer(questionId, questionIndex) {
      this.loadQuestion = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregquestions/${questionId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.$toast.success("Question Deleted", {
            position: "top",
            duration: 3000,
          });
          this.approvalQuestions.splice(questionIndex, 1);
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.$toast.success("Question Deleted", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          error;
        });
    },
    async prepareSurveyQuestionsPostData() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        const lastQuestionCheck = await this.approvalQuestions[
          this.approvalQuestions.length - 1
        ];
        if (lastQuestionCheck.id != "") {
          this.approvalBtn.sub = "Exiting....";
          this.approvalBtn.disabled = true;
          return setTimeout(() => {
            this.closeCurrentTab();
          }, 3000);
        }
        this.approvalBtn.add = "Please wait...";
        this.approvalBtn.sub = "Please wait...";
        this.loadQuestion = true;
        this.approvalBtn.disabled = true;
        for (let surveyQues in this.approvalQuestions) {
          const ques = this.approvalQuestions[surveyQues];
          if (ques.id == "") {
            const surveyQuestions = await {
              question_no: ques.question_no,
              name: ques.name,
              summary: ques.summary,
              answers: ques.answers,
              allow_attachment: ques.allow_attachment,
              input_attributes: ques.input_attributes,
              onlineregistration_id: this.registrationDetails.id,
              grouping_tag: "",
            };

            let questionCreation = await this.createApprovalQuestions(
              surveyQuestions
            );

            if (questionCreation) {
              this.$toast.success(
                `Question number ${ques.question_no} created`,
                {
                  position: "top-right",
                  duration: 3000,
                }
              );
              if (surveyQues == this.approvalQuestions.length - 1) {
                this.clearResponse(true);
              }
            }
            if (!questionCreation) {
              this.$toast.error("Something Went Wrong, please try again!", {
                position: "top",
                duration: 3000,
              });
              this.clearResponse();
            }
          }
        }
      }
    },
    async createApprovalQuestions(surveyQuestions) {
      let status = null;
      const url = this.$store.getters.getAPIKey.approvalQuestions;
      await this.$axios
        .post(url, surveyQuestions)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    async getApprovalQuestions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.registrationDetails.id}/onlineregquestions`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.approvalQuestions = [];
          let questions = response.data;
          if (questions.length != 0) {
            questions.forEach((ques) => {
              this.approvalQuestions.push({
                id: ques.id,
                question_no: ques.question_no,
                name: ques.name,
                summary: ques.summary,
                answers: ques.answers,
                allow_attachment: ques.allow_attachment == 1 ? true : false,
                input_attributes: ques.input_attributes,
                onlineregistration_id: ques.onlineregistration_id,
                grouping_tag: ques.grouping_tag,
                disabled: true,
              });
            });
            this.addQues = true;
            this.fetchData = false;
          } else {
            this.fetchData = false;
          }
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getRegistrationDetails() {
      this.fetchData = true;
      const surveyApprovalId = this.$route.params.approval_id;
      const url =
        this.$store.getters.getAPIKey.surveyApprovals + `/${surveyApprovalId}`;
      this.$axios
        .get(url)
        .then((response) => {
          this.registrationDetails = {
            id: response.data.id,
            name: response.data.name,
            summary: response.data.summary,
            academic_year: response.data.academic_year,
            form_guideline: response.data.form_guideline ?? '',
            start_at: new Date(
              moment(
                moment
                  .utc(response.data.start_at, "YYYY-MM-DD HH:mm:ss")
                  .toDate()
              ).format()
            ),
            end_at: new Date(
              moment(
                moment.utc(response.data.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
              ).format()
            ),
            grouping_tag: response.data.grouping_tag,
            comment_feedback:
              response.data.comment_feedback == 1 ? true : false,
            hidden: response.data.hidden == 1 ? true : false,
            participants_only:
              response.data.participants_only == 1 ? true : false,
            is_anonymous: response.data.is_anonymous == 1 ? true : false,
          };

          this.form_guideline=this.registrationDetails.form_guideline; // compare and save changes
          this.getApprovalQuestions();

          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async prepareAddingQuestion() {
      if (this.approvalQuestions.length == 0) {
        this.addQuestion();
      } else {
        await this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          this.addQuestion();
        }
      }
    },
    addQuestion() {
      this.approvalQuestions.push({
        id: "",
        question_no: "",
        name: "",
        summary: "",
        answers: [],
        allow_attachment: false,
        input_attributes: [
          {
            shortAnswer: false,
            paragraph: false,
            multiChoice: false,
            checkboxes: false,
            dropdown: false,
            answerMandatory: true,
          },
        ],
        onlineregistration_id: this.registrationDetails.id
          ? this.registrationDetails.id
          : "",
        grouping_tag: "",
        disabled: false,
      });
    },
    clearResponse(status) {
      this.approvalBtn.add = "Add Question";
      this.approvalBtn.sub = "Submit";
      this.loadQuestion = false;

      setTimeout(() => {
        if (status) {
          this.closeCurrentTab();
        }
        this.loadQuestion = false;
        this.approvalBtn.disabled = false;
      }, 3000);
    },
    editApprovalQuestion(questionIndex) {
      this.approvalBtn.disabled = true;
      const question = this.approvalQuestions[questionIndex];
      this.approvalQuestions.forEach((question) => {
        if (question.id != "") {
          question.disabled = true;
        }
      });
      question.disabled = false;
    },
    async prepareApprovalQuestionsForUpdating(surveyQuesForUpdate) {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.loadQuestion = true;

        const ques = this.approvalQuestions[surveyQuesForUpdate];
        const surveyQuestion = await {
          question_no: ques.question_no,
          name: ques.name,
          summary: ques.summary,
          answers: ques.answers,
          allow_attachment: ques.allow_attachment,
          input_attributes: ques.input_attributes,
          onlineregistration_id: this.registrationDetails.id,
          grouping_tag: "",
        };

        let questionUpdate = await this.updateApprovalQuestion(
          surveyQuestion,
          ques.id
        );

        if (questionUpdate) {
          this.$toast.success(`Question number ${ques.question_no} updated`, {
            position: "top",
            duration: 3000,
          });

          await this.clearResponse(false);
          await this.getApprovalQuestions();
        }
        if (!questionUpdate) {
          this.$toast.error("Something Went Wrong, please try again!", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse(false);
        }
      }
    },
    async updateApprovalQuestion(surveyQuestion, questionId) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.approvalQuestions + `/${questionId}`;
      await this.$axios
        .put(url, surveyQuestion)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },

    async saveFormGuideline() {
      const url =
        this.$store.getters.getAPIKey.surveyApprovals +
        `/${this.registrationDetails.id}`;
      await this.$axios
        .put(url, {form_guideline:this.form_guideline})
        .then(() => {
          this.registrationDetails.form_guideline=this.form_guideline;
          this.$toast.success("Updated successfully", {
            position: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to save", {
            position: "top",
            duration: 3000,
          });
        });
    },

    async copyQuestionsModel() {
      await this.$bvModal.show("copyQuestion");
      this.regEventsForCopy = [];
      this.regEventIdForCopy = null;
      this.regEventsForCopyStatus = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregistrations`;
      await this.$axios
        .get(url)
        .then((response) => {
          const onlineRegs = response.data;
          onlineRegs.forEach((onlineReg) => {
            this.regEventsForCopy.push({
              value: onlineReg.id,
              text: onlineReg.name,
            });
          });

          this.regEventsForCopyStatus = false;
        })
        .catch(() => {
          this.$toast.error("Failed to load", {
            position: "top",
            duration: 3000,
          });
        });
    },
    async copyQuestions() {
      this.regEventsForCopyStatus = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/onlineregquestions/copy`;
      await this.$axios
        .post(url, {
          from: this.regEventIdForCopy,
          to: this.$route.params.approval_id,
        })
        .then(() => {
          this.$toast.success("Copyied successfully", {
            position: "top",
            duration: 3000,
          });
          this.regEventsForCopyStatus = false;
          this.$bvModal.hide("copyQuestions");
          this.getApprovalQuestions();
        })
        .catch(() => {
          this.$toast.error("Failed to load", {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style >
.surveyApprovalQuestions {
  overflow: hidden;
}
</style>
